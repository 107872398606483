import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import React from "react";
import styled from "styled-components";
import { RimoVoiceGenericLPProps } from "./RimoVoiceGenericLP";
import { Lead, MOBILE_BREAKPOINT, SubLead } from "./shared";
import { LocaleLink } from "../../components/LocaleLink";

const TEXT = {
  writing: {
    head: "取材・執筆の他にも、様々なシーンで活躍",
    subhead: "音声でのやりとりを必要とする数多くの業務の記録、情報共有、効率化に",
    sceneList: [
      { imageUrl: "/assets/about_genelic/scene6.png", caption: "議事録", link: "meeting" },
      { imageUrl: "/assets/about_genelic/scene2.png", caption: "ユーザーリサーチ", link: "user_research" },
      { imageUrl: "/assets/about_genelic/scene3.png", caption: "商談・営業改善", link: "business_analysis" },
      { imageUrl: "/assets/about_genelic/scene4.png", caption: "研修・ウェビナー", link: "training" },
    ],
  },
  proceedings: {
    head: "会議の他にも、様々なシーンで活躍",
    subhead: "音声でのやりとりを必要とする数多くの業務の記録、情報共有、効率化に",
    sceneList: [
      { imageUrl: "/assets/about_genelic/scene1.png", caption: "取材・執筆", link: "writing" },
      { imageUrl: "/assets/about_genelic/scene2.png", caption: "ユーザーリサーチ", link: "user_research" },
      { imageUrl: "/assets/about_genelic/scene3.png", caption: "商談・営業改善", link: "business_analysis" },
      { imageUrl: "/assets/about_genelic/scene4.png", caption: "研修・ウェビナー", link: "training" },
    ],
  },
  training: {
    head: "研修の他にも、様々なシーンで活躍",
    subhead: "音声でのやりとりを必要とする数多くの業務の記録、情報共有、効率化に",
    sceneList: [
      { imageUrl: "/assets/about_genelic/scene6.png", caption: "議事録", link: "meeting" },
      { imageUrl: "/assets/about_genelic/scene2.png", caption: "ユーザーリサーチ", link: "user_research" },
      { imageUrl: "/assets/about_genelic/scene3.png", caption: "商談・営業改善", link: "business_analysis" },
      { imageUrl: "/assets/about_genelic/scene1.png", caption: "取材・執筆", link: "writing" },
    ],
  },
  businessAnalysis: {
    head: "商談分析の他にも、様々なシーンで活躍",
    subhead: "音声でのやりとりを必要とする数多くの業務の記録、情報共有、効率化に",
    sceneList: [
      { imageUrl: "/assets/about_genelic/scene6.png", caption: "議事録", link: "meeting" },
      { imageUrl: "/assets/about_genelic/scene2.png", caption: "ユーザーリサーチ", link: "user_research" },
      { imageUrl: "/assets/about_genelic/scene1.png", caption: "取材・執筆", link: "writing" },
      { imageUrl: "/assets/about_genelic/scene4.png", caption: "研修・ウェビナー", link: "training" },
    ],
  },
  userResearch: {
    head: "ユーザーリサーチの他にも、様々なシーンで活躍",
    subhead: "音声でのやりとりを必要とする数多くの業務の記録、情報共有、効率化に",
    sceneList: [
      { imageUrl: "/assets/about_genelic/scene6.png", caption: "議事録", link: "meeting" },
      { imageUrl: "/assets/about_genelic/scene1.png", caption: "取材・執筆", link: "writing" },
      { imageUrl: "/assets/about_genelic/scene3.png", caption: "商談・営業改善", link: "business_analysis" },
      { imageUrl: "/assets/about_genelic/scene4.png", caption: "研修・ウェビナー", link: "training" },
    ],
  },
  transcription: {
    head: "様々なシーンで活躍",
    subhead: "音声でのやりとりを必要とする数多くの業務の記録、情報共有、効率化に",
    sceneList: [
      { imageUrl: "/assets/about_genelic/scene6.png", caption: "議事録", link: "meeting" },
      { imageUrl: "/assets/about_genelic/scene1.png", caption: "取材・執筆", link: "writing" },
      { imageUrl: "/assets/about_genelic/scene3.png", caption: "商談・営業改善", link: "business_analysis" },
      { imageUrl: "/assets/about_genelic/scene4.png", caption: "研修・ウェビナー", link: "training" },
      { imageUrl: "/assets/about_genelic/scene2.png", caption: "ユーザーリサーチ", link: "user_research" },
    ],
  },
};
export const SceneList: React.FC<Pick<RimoVoiceGenericLPProps, "target">> = (props) => {
  const { customT } = useClientTranslation();
  const data = TEXT[props.target];
  return (
    <Content>
      <Head>{customT(data.head)}</Head>
      <Subhead>{customT(data.subhead)}</Subhead>
      <Scene>
        {data.sceneList.map((scene) => (
          <SceneItem key={scene.caption}>
            <LocaleLink href={`/usecases/${scene.link}`}>
              <Img src={customT(scene.imageUrl)} alt={customT(scene.caption)} />
              <Caption>{customT(scene.caption)}</Caption>
            </LocaleLink>
          </SceneItem>
        ))}
      </Scene>
    </Content>
  );
};

const Content = styled.div`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    margin-top: 72px;
    padding: 72px 0;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    padding: 32px 0;
  }
`;
const Head = styled(Lead)``;
const Subhead = styled(SubLead)``;
const Scene = styled.div`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    align-items: center;
    display: flex;
    gap: 32px;
    margin-top: 48px;
    justify-content: center;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    margin-top: 46px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px 24px;
  }
`;
const Img = styled.img`
  border-radius: 16px;
  object-fit: cover;
  width: 100%;
  transition: all 200ms;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    height: 160px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    height: 120px;
  }
`;
const SceneItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    ${Img} {
      transform: scale(1.03);
    }
  }
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    flex-shrink: 0;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    max-width: 158px;
  }
`;
const Caption = styled.p`
  font-weight: 700;
  text-align: center;
  text-decoration: underline;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    margin-top: 16px;
    font-size: 22px;
    line-height: 32px;
    height: 32px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    margin-top: 8px;
    font-size: 14px;
    line-height: 24px;
  }
`;
