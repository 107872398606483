import { FooterTop } from "./FooterTop";
import { FooterBottom } from "./FooterBottom";

interface FooterProps {
  showHelp?: boolean;
  variant?: "full" | "simple";
}

export const Footer = ({ showHelp = false, variant = "full" }: FooterProps) => {
  return (
    <>
      {variant === "full" && <FooterTop />}
      <FooterBottom showHelp={showHelp} />
    </>
  );
};
