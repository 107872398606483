import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import Link from "next/link";
import { useThemeMode } from "@rimo/ui-old";
import Image from "next/image";
import { useState, useEffect } from "react";

export const FooterTop = () => {
  const { customT } = useClientTranslation();
  const themeMode = useThemeMode();

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const logoSrc = mounted && themeMode === "dark" ? "/assets/logo_white.svg" : "/assets/rimo_logo.svg";

  return (
    <div className="w-full bg-muted/50">
      <div className="flex gap-10 pb-11 pt-8 max-[1679px]:w-full max-[1679px]:px-[160px] max-xl:gap-6 max-[959px]:mx-auto max-[959px]:w-[90%] max-[959px]:max-w-[800px] max-[959px]:px-0 max-md:flex-col max-md:gap-2 max-md:pb-8 min-[1679px]:mx-auto min-[1679px]:max-w-[1200px]">
        <div className="flex flex-1 flex-col gap-2 max-md:mb-3">
          <Link href="/about/voice" className="w-fit">
            <Image
              key={`logo-${mounted ? themeMode : "light"}`}
              src={logoSrc}
              alt="Rimo"
              width={80}
              height={32}
              className="h-7 w-auto object-contain"
              priority
            />
          </Link>
          <h3 className="text-xs text-muted-foreground">{customT("AI 文字起こし・要約")}</h3>
        </div>

        {/* 空白スペース */}
        <div className="flex-1 max-md:hidden" aria-hidden="true" />

        <div className="flex flex-1 flex-col items-start gap-2 text-sm">
          <Link href="/case-studies" className="text-muted-foreground hover:text-foreground">
            {customT("導入事例")}
          </Link>
          <Link href="/blogs" className="text-muted-foreground hover:text-foreground">
            {customT("お役立ち情報")}
          </Link>
          <Link
            href="https://guide.rimo.app"
            target="_blank"
            rel="noopener noreferrer"
            className="text-muted-foreground hover:text-foreground"
          >
            {customT("ヘルプ")}
          </Link>
        </div>

        <div className="flex flex-1 flex-col items-start gap-2 text-sm">
          <Link href="/about/voice#contact-form" className="text-muted-foreground hover:text-foreground">
            {customT("資料請求")}
          </Link>
          <Link href="/about/voice_partners" className="text-muted-foreground hover:text-foreground">
            {customT("販売パートナー")}
          </Link>
        </div>

        <div className="flex flex-1 flex-col items-start gap-3 text-sm">
          <Link
            href="/contact"
            className="text-muted-foreground hover:text-foreground"
            target="_blank"
            rel="noopener noreferrer"
          >
            {customT("お問い合わせ")}
          </Link>
        </div>
      </div>
    </div>
  );
};
