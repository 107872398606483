import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import { useLocaleContext } from "@rimo/frontend/contexts/LocaleProvider";
import { useSearchParamsString } from "@rimo/frontend/hooks/useNavigation";
import { fallbackLng } from "@rimo/i18n/settings";
import Link from "next/link";
import { LocaleLink } from "../../components/LocaleLink";

interface FooterBottomProps {
  showHelp?: boolean;
}

export const FooterBottom = ({ showHelp = false }: FooterBottomProps) => {
  const { customT } = useClientTranslation();
  const { locale } = useLocaleContext();
  const searchParamsString = useSearchParamsString();

  return (
    <footer className="w-full bg-background">
      <div className="mx-auto flex items-center justify-between py-8 max-[1679px]:w-full max-[1679px]:px-[160px] max-lg:flex-col max-[959px]:mx-auto max-[959px]:w-[90%] max-[959px]:max-w-[800px] max-[959px]:px-0 max-md:flex-col max-md:items-start md:items-start min-[1679px]:mx-auto min-[1679px]:max-w-[1200px]">
        <nav className="flex flex-col items-center gap-4 text-xs text-muted-foreground max-lg:w-full max-lg:justify-center max-lg:text-center max-md:items-start max-md:gap-2 md:flex-row md:space-y-0">
          {showHelp && (
            <Link href="https://guide.rimo.app/ja/" className="hover:text-foreground">
              {customT("ヘルプ")}
            </Link>
          )}
          <Link
            href={
              locale === fallbackLng
                ? `/policies/terms${searchParamsString}`
                : `/global_policies/terms${searchParamsString}`
            }
            className="hover:text-foreground"
          >
            {customT("利用規約")}
          </Link>
          <Link
            href={
              locale === fallbackLng
                ? `/policies/privacy${searchParamsString}`
                : `/global_policies/privacy${searchParamsString}`
            }
            className="hover:text-foreground"
          >
            {customT("プライバシーポリシー")}
          </Link>
          <LocaleLink href={`/policies/security${searchParamsString}`} className="hover:text-foreground">
            {customT("情報セキュリティ方針")}
          </LocaleLink>
          <LocaleLink href={`/policies/scta${searchParamsString}`} className="hover:text-foreground">
            {customT("特定商取引法に基づく表記")}
          </LocaleLink>
          <LocaleLink href={`/about/company${searchParamsString}`} className="hover:text-foreground">
            {customT("運営会社")}
          </LocaleLink>
        </nav>
        <div className="text-xs text-muted-foreground max-lg:mt-4 max-lg:w-full max-lg:text-center max-md:mt-4 max-md:w-full max-md:text-center">
          Rimo LLC. © {new Date().getFullYear()}
        </div>
      </div>
    </footer>
  );
};
