import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import React from "react";
import styled from "styled-components";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import GraphicEqOutlinedIcon from "@mui/icons-material/GraphicEqOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import DevicesIcon from "@mui/icons-material/Devices";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";

import { RimoVoiceGenericLPProps } from "./RimoVoiceGenericLP";
import { MOBILE_BREAKPOINT } from "./shared";
import { Trans } from "react-i18next/TransWithoutContext";

const TEXT = {
  writing: {
    head: ["機能を活用して", "制作をもっと快適に"],
    points: [
      { Icon: AssignmentTurnedInOutlinedIcon, text: "1時間の取材を5分で変換する高速性能" },
      { Icon: DevicesOutlinedIcon, text: "PC・スマホに対応したシンプルなUI" },
      { Icon: ShareOutlinedIcon, text: "URLでかんたん共有" },
      { Icon: IosShareOutlinedIcon, text: "テキスト・表計算・字幕などへの書き出し" },
      { Icon: LocalLibraryOutlinedIcon, text: "単語登録で各業界・組織の専門用語に対応" },
      { Icon: GraphicEqOutlinedIcon, text: "雑音・意味のない発声を自動除去" },
    ],
  },
  proceedings: {
    head: ["あなたの組織の議事録を", "変えるための機能が", "備わっています"],
    points: [
      { Icon: GraphicEqOutlinedIcon, text: "雑音・意味のない<span>発声を自動除去</span>" },
      { Icon: IosShareOutlinedIcon, text: "テキスト・表計算・字幕などへの<span>書き出し</span>" },
      { Icon: ShareOutlinedIcon, text: "URLで組織内外に<span>かんたん共有</span>" },
      { Icon: WorkspacesOutlinedIcon, text: "<span>共同編集</span>で議事録をより完璧に" },
      { Icon: LocalLibraryOutlinedIcon, text: "<span>単語登録</span>で各業界・組織の専門用語に対応" },
      { Icon: TimerOutlinedIcon, text: "個人・チームごとの<span>利用時間を管理</span>" },
      { Icon: SecurityOutlinedIcon, text: "ISO27017認証取得で<span>安心のセキュリティ</span>" },
    ],
  },
  training: {
    head: ["機能を活用して", "研修の動画の共有を", "もっと快適に"],
    points: [
      { Icon: GraphicEqOutlinedIcon, text: "雑音・意味のない<span>発声を自動除去</span>" },
      { Icon: IosShareOutlinedIcon, text: "YouTube用字幕データの<span>書き出し</span>" },
      { Icon: ShareOutlinedIcon, text: "URLで組織内外に<span>かんたん共有</span>" },
      { Icon: DevicesIcon, text: "<span>PC・スマホに対応</span>したシンプルなUI" },
      { Icon: LocalLibraryOutlinedIcon, text: "<span>単語登録</span>で各業界・組織の専門用語に対応" },
      { Icon: CreateNewFolderOutlinedIcon, text: "<span>フォルダを分類</span>してチームごとに利用" },
      { Icon: SecurityOutlinedIcon, text: "ISO27017認証取得で<span>安心のセキュリティ</span>" },
      { Icon: WorkspacesOutlinedIcon, text: "<span>共同編集</span>で研修動画の字幕をより完璧に" },
    ],
  },
  businessAnalysis: {
    head: ["商談記録から振り返りまで", "必要な機能が揃っています"],
    points: [
      { Icon: GraphicEqOutlinedIcon, text: "雑音・意味のない<span>発声を自動除去</span>" },
      { Icon: IosShareOutlinedIcon, text: "YouTube用字幕データの<span>書き出し</span>" },
      { Icon: ShareOutlinedIcon, text: "URLで組織内外に<span>かんたん共有</span>" },
      { Icon: DevicesIcon, text: "<span>PC・スマホに対応</span>したシンプルなUI" },
      { Icon: LocalLibraryOutlinedIcon, text: "<span>単語登録</span>で各業界・組織の専門用語に対応" },
      { Icon: CreateNewFolderOutlinedIcon, text: "<span>フォルダを分類</span>してチームごとに利用" },
      { Icon: SecurityOutlinedIcon, text: "ISO27017認証取得で<span>安心のセキュリティ</span>" },
    ],
  },
  userResearch: {
    head: ["必要な機能が", "揃っています"],
    points: [
      { Icon: GraphicEqOutlinedIcon, text: "雑音・意味のない<span>発声を自動除去</span>" },
      { Icon: IosShareOutlinedIcon, text: "YouTube用字幕データの<span>書き出し</span>" },
      { Icon: ShareOutlinedIcon, text: "URLで組織内外に<span>かんたん共有</span>" },
      { Icon: DevicesIcon, text: "<span>PC・スマホに対応</span>したシンプルなUI" },
      { Icon: LocalLibraryOutlinedIcon, text: "<span>単語登録</span>で各業界・組織の専門用語に対応" },
      { Icon: CreateNewFolderOutlinedIcon, text: "<span>フォルダを分類</span>してチームごとに利用" },
      { Icon: SecurityOutlinedIcon, text: "ISO27017認証取得で<span>安心のセキュリティ</span>" },
    ],
  },
  transcription: {
    head: ["議事録や記事編集に使える", "便利な機能"],
    points: [
      { Icon: GraphicEqOutlinedIcon, text: "雑音・意味のない<span>発声を自動除去</span>" },
      { Icon: IosShareOutlinedIcon, text: "YouTube用字幕データの<span>書き出し</span>" },
      { Icon: ShareOutlinedIcon, text: "URLで組織内外に<span>かんたん共有</span>" },
      { Icon: DevicesIcon, text: "<span>PC・スマホに対応</span>したシンプルなUI" },
      { Icon: LocalLibraryOutlinedIcon, text: "<span>単語登録</span>で各業界・組織の専門用語に対応" },
      { Icon: CreateNewFolderOutlinedIcon, text: "<span>フォルダを分類</span>してチームごとに利用" },
      { Icon: SecurityOutlinedIcon, text: "ISO27017認証取得で<span>安心のセキュリティ</span>" },
    ],
  },
};
export const SmallPointList: React.FC<Pick<RimoVoiceGenericLPProps, "target">> = (props) => {
  const { customT } = useClientTranslation();
  const data = TEXT[props.target];
  return (
    <Content>
      <LeftBlock>
        {data.head.map((t) => (
          <span key={t}>{customT(t)}</span>
        ))}
      </LeftBlock>
      <RightBlock>
        {data.points.map((point) => (
          <PointItem key={point.text}>
            <point.Icon />
            <PointText>
              <Trans i18nKey={point.text} components={{ span: <span /> }} />
            </PointText>
          </PointItem>
        ))}
      </RightBlock>
    </Content>
  );
};

const Content = styled.div`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    padding: 72px 0;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    padding: 32px 0;
  }
`;
const LeftBlock = styled.h2`
  font-weight: 700;
  span {
    display: inline-block;
  }
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    width: 50%;
    font-size: 40px;
    line-height: 56px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    font-size: 28px;
    line-height: 40px;
  }
`;
const RightBlock = styled.ul`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    width: 48%;
    max-width: 628px;
    margin: 72px 0;
    padding: 8px 0;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    padding: 16px 0;
  }
`;
const PointItem = styled.li`
  display: flex;
  align-items: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    padding: 16px 0;
    svg {
      width: 32px;
      height: 32px;
    }
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    padding: 8px 0;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  svg {
    color: ${(p) => p.theme.vars.palette.primary.main};
  }
  &:last-child {
    border: none;
  }
`;
const PointText = styled.p`
  font-weight: 400;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.342857px;
    margin-left: 20px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    margin-left: 16px;
    font-size: 16px;
    line-height: 24px;
  }
  span {
    font-weight: 500;
  }
`;
